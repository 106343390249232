import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../layouts'
import ReactMarkdown from 'react-markdown'
// import DEBUG from '../../utils/debug'
import Moment from 'react-moment'
import ads from '../../images/fleur_gran_opening_1-663x1024.jpg'
/**
 * Single post template
 */
export default ({ data }) => {
  return (
    <Layout
      head={{
        title: data.strapiPosts.title,
        meta: {
          title: data.strapiPosts.metaTitle,
          keywords: data.strapiPosts.metaKeywords,
          description: data.strapiPosts.metaDescription,
        },
      }}
    >
      <div className="page-heading">
        <div className="container">
          <div className="blog-title-heading">
            {data.strapiPosts.tags.map(tag => (
              <Link
                to={`tags/${tag.name.replace(/\s+/g, '-').toLowerCase()}`}
                className="headline-span"
              >
                <span key={tag.id}>{tag.name}</span>
              </Link>
            ))}
            <h1 className="mt-3">
              <strong>{data.strapiPosts.title}</strong>
            </h1>
            <h5 className="blog-post-date">
              <i className="fas fa-calendar-o" />
              <Moment format="MMMM DD YYYY" date={data.strapiPosts.published} />
            </h5>
          </div>
        </div>
        <div
          class="page-heading-bg"
          style={{
            backgroundImage: `url(${data.strapiPosts.banner.url})`,
          }}
        />
      </div>
      {/*<DEBUG data={data} />*/}
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div>
                <ReactMarkdown
                  escapeHtml={false}
                  source={data.strapiPosts.body}
                />
              </div>
            </div>
            <div className="col-md-4 offset-md-1">
              <div />
              <div className="blog-subscribe-form">
                <h4>Get the latest news straight to your inbox!</h4>
                <span>Enter your email to subscribe.</span>
                <form
                  action="https://emailmarketing.webriq.services/contact/add?version=2"
                  data-id="oTlFAmBbIl"
                  method="post"
                  id="ewf_subscriptionForm_oTlFAmBbIl"
                  className="Inline None Placeholders subscribe-form"
                >
                  <fieldset
                    style={{ border: 'none' }}
                    className="webform-custominputs"
                  >
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      required=""
                      placeholder="Your email address"
                    />
                    <input
                      type="submit"
                      name="submit"
                      value="Subscribe"
                      id="eesubmit"
                      className="btn btn-primary gradient-btn"
                    />
                  </fieldset>
                  <fieldset
                    className="webform-options"
                    style={{ border: 'none' }}
                  >
                    <input
                      type="hidden"
                      name="publicaccountid"
                      value="978a5f0a-48a6-4e8b-b5d1-d4d1142bf19c"
                    />
                    <input type="hidden" name="publicformid" value="" />
                    <input type="hidden" name="returnUrl" value="" />
                    <input type="hidden" name="activationReturnUrl" value="" />
                    <input type="hidden" name="alreadyactiveurl" value="" />
                    <input type="hidden" name="activationTemplate" value="" />
                    <input type="hidden" name="source" value="WebForm" />
                    <input
                      type="hidden"
                      id="ewf_captcha"
                      name="captcha"
                      value="false"
                    />
                    <input type="hidden" name="notifyEmail" value="" />
                    <button
                      type="button"
                      name="dismissForm"
                      id="ewf_dismissForm"
                      style={{ display: 'none' }}
                    >
                      X
                    </button>
                  </fieldset>
                </form>
              </div>
              <div>
                <span className="headline-span">Recent News</span>
                {data.allStrapiPosts.edges.map(post => (
                  <div className="row trending-now-item">
                    <div key={post.node.id} className="col-md-5">
                      <a href={post.node.fields.slug}>
                        <div
                          className="trending-now-image"
                          style={{
                            backgroundImage: `url(${post.node.banner.url})`,
                          }}
                        >
                          <div className="date-trending">
                            <span className="date">
                              <Moment
                                format="MMM DD"
                                date={post.node.published}
                              />
                              <i className="fas fa-long-arrow-alt-right" />
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-7">
                      <a href={post.node.fields.slug} className="news-headline">
                        <h5>{post.node.title}</h5>
                      </a>
                    </div>
                  </div>
                ))}
                <a href="/">
                  <img
                    src={ads}
                    alt="cbd confections and supplements"
                    className="mb-4 img-fluid"
                  />
                </a>
              </div>
              <div className="blog-categs">
                <h4>Browse by Category</h4>
                <ul className="blog-categ-list">
                  {data.allStrapiCategories.edges.map(category => (
                    <li key={category.node.id}>
                      <Link
                        to={`categories/${category.node.name
                          .replace(/\s+/g, '-')
                          .toLowerCase()}`}
                      >
                        <span key={category.node.id}>{category.node.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiPosts(id: { eq: $id }) {
      id
      title
      body
      excerpt
      banner {
        url
      }
      author {
        id
        email
        profile
      }
      fields {
        slug
      }
      tags {
        id
        name
      }
      metaTitle
      metaKeywords
      metaDescription
    }

    allStrapiPosts(limit: 5) {
      edges {
        node {
          id
          title
          fields {
            slug
          }
          banner {
            url
          }
          published
        }
      }
    }

    allStrapiCategories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
